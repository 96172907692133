import {ApolloProvider} from '@apollo/client';

import {isServerSide} from '../lib/env';

import {graphqlClient} from './graphql-client';

export const withApollo = (PageComponent, {ssr = true} = {}) => {
    const WithApollo = ({apolloState = undefined, ...pageProps}) => {
        const client = pageProps?.pageProps?.apolloClient || graphqlClient(apolloState);

        return (
            <ApolloProvider client={client}>
                <PageComponent {...pageProps} />
            </ApolloProvider>
        );
    };

    if (ssr || PageComponent.getInitialProps) {
        WithApollo.getInitialProps = async (ctx) => {
            const {AppTree, res} = ctx;

            // eslint-disable-next-line no-param-reassign,no-multi-assign
            const apolloClient = ctx.apolloClient = graphqlClient(undefined, ctx);

            let pageProps = {};

            if (PageComponent.getInitialProps) {
                pageProps = await PageComponent.getInitialProps(ctx);
            }

            if (isServerSide()) {
                if (res && res.finished) {
                    return pageProps;
                }

                if (ssr) {
                    try {
                        const {getDataFromTree} = await import('@apollo/client/react/ssr');

                        await getDataFromTree(
                            <AppTree
                                pageProps={{
                                    ...pageProps,
                                    apolloClient,
                                }}
                            />,
                        );
                    } catch (error) {
                        // eslint-disable-next-line no-console
                        console.error('Error while running `getDataFromTree`', error);
                    }
                }
            }

            const apolloState = apolloClient.cache.extract();

            return {
                ...pageProps,
                apolloState,
            };
        };
    }

    return WithApollo;
};
