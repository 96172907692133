import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Modal from '@hy-vee/web-core/lib/components/modal';

import {
    clearGeolocationError,
    toggleActiveModal,
    setDeliveryAddressCheckerValues,
    reverseGeocodeLocation,
} from '../../action-creators';

import CheckDeliveryEligibilityModalContent from './check-delivery-eligibility-modal-content';

const CheckDeliveryEligibilityModalWrapper = ({actions, deliveryAddressCheckerValues, geolocationError}) => (
    <Modal
        data-testid="delivery-eligibility-modal"
        onClose={() => {
            actions.toggleActiveModal();
            actions.clearGeolocationError();
            actions.setDeliveryAddressCheckerValues('');
        }}
        size="medium"
        title="Check Delivery Area"
    >
        <CheckDeliveryEligibilityModalContent
            clearGeolocationError={actions.clearGeolocationError}
            deliveryAddressCheckerValues={deliveryAddressCheckerValues}
            geolocationError={geolocationError}
            reverseGeocodeLocation={actions.reverseGeocodeLocation}
            setValues={actions.setDeliveryAddressCheckerValues}
        />
    </Modal>
);

const mapStateToProps = (state) => ({
    deliveryAddressCheckerValues: state.deliveryEligibility.deliveryAddressCheckerValues,
    geolocationError: state.deliveryEligibility.geolocationError,
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            clearGeolocationError,
            reverseGeocodeLocation,
            setDeliveryAddressCheckerValues,
            toggleActiveModal,
        },
        dispatch,
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckDeliveryEligibilityModalWrapper);
