import {useContext} from 'react';

import withUserDetails from '../utils/with-user-details';
import {hasFeature} from '../utils/features';
import {User} from '../context/user';

const FeatureToggle = ({featureName, component, fallback = null}) => {
    const {features, loading} = useContext(User);

    if (loading) {
        return null;
    }

    return hasFeature(features, featureName) ? component : fallback;
};

export default withUserDetails(FeatureToggle);
