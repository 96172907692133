import {useState} from 'react';
import 'regenerator-runtime/runtime';
import StoreSelection from '@hy-vee/web-store-select/lib/components/store-selection/store-selection';

import {
    EnterAddressLabel,
    ModalContentContainer,
    StyledEligibleForDelivery,
    StyledEligibleForDeliveryStatus,
} from './styles';
import CheckDeliveryEligibilityQuery from './check-delivery-eligibility-query';

const GOOGLE_MAP_API_KEY = 'AIzaSyCA2viWs8WyiMI39DiUti_r6ZijCL4wilA';

const CheckDeliveryEligibilityModalContent = ({
    clearGeolocationError,
    setValues,
    deliveryAddressCheckerValues,
    reverseGeocodeLocation,
    geolocationError,
}) => {
    const [geolocationNotAllowed, setGeolocationNotAllowed] = useState(null);
    const shouldDisplayError = Boolean(geolocationError || geolocationNotAllowed);

    return (
        <ModalContentContainer>
            <EnterAddressLabel>{'Enter Your Address'}</EnterAddressLabel>
            <StoreSelection
                autoFocus
                label="Enter your address"
                mapsApiKey={GOOGLE_MAP_API_KEY}
                onCurrentLocationClick={() => {
                    navigator.geolocation.getCurrentPosition(
                        ({coords: {latitude, longitude}}) => {
                            reverseGeocodeLocation(latitude, longitude);
                        },
                        () => {
                            setGeolocationNotAllowed(
                                'Please restart your browser and authorize Hy-Vee to get your location.',
                            );
                        },
                    );
                }}
                onLocationSelect={setValues}
                placeholder="Delivery address"
                requiredAddressComponents={['postal_code']}
                suggestionTypes={['address']}
            />
            {shouldDisplayError && (
                <div style={{marginTop: '8px'}}>
                    <StyledEligibleForDelivery>
                        <StyledEligibleForDeliveryStatus isEligible={false}>
                            {geolocationError || geolocationNotAllowed}
                        </StyledEligibleForDeliveryStatus>
                    </StyledEligibleForDelivery>
                </div>
            )}
            {deliveryAddressCheckerValues.addressOne && (
                <CheckDeliveryEligibilityQuery
                    deliveryAddressCheckerValues={deliveryAddressCheckerValues}
                    geolocationError={geolocationError || geolocationNotAllowed}
                    resetError={() => {
                        clearGeolocationError();
                        setGeolocationNotAllowed(null);
                    }}
                />
            )}
        </ModalContentContainer>
    );
};

export default CheckDeliveryEligibilityModalContent;
