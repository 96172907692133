import React from 'react';
import {Provider} from 'react-redux';
import {bindActionCreators} from 'redux';

import {isServerSide} from '../lib/env';
import * as ActionCreators from '../action-creators';
import HyVeePlusWeb from '../hy-vee-plus-web';

import {getOrCreateStore, __NEXT_REDUX_STORE__} from './store';

const withRedux = (WrappedComponent) => {
    class WithRedux extends React.Component {
        static async getInitialProps(ctx) {
            const shouldGetInitialState = isServerSide() || !window[__NEXT_REDUX_STORE__];

            const reduxStore = getOrCreateStore();

            // eslint-disable-next-line no-param-reassign
            ctx.reduxStore = reduxStore;

            if (shouldGetInitialState && ctx.req) {
                await ActionCreators.getInitialState(ctx.req)(reduxStore.dispatch);
            }

            let pageProps = {};

            if (WrappedComponent.getInitialProps) {
                pageProps = await WrappedComponent.getInitialProps(ctx);
            }

            return {
                ...pageProps,
                reduxState: ctx.reduxStore.getState(),
            };
        }

        constructor(props) {
            super(props);

            this.reduxStore = getOrCreateStore(props.pageProps.reduxState);
        }

        render() {
            const actions = bindActionCreators(ActionCreators, this.reduxStore.dispatch);

            return (
                <Provider store={this.reduxStore}>
                    <HyVeePlusWeb
                        reduxStore={this.reduxStore}
                        setCustomerAndStoreData={actions.setCustomerAndStoreData}
                    >
                        <WrappedComponent {...this.props} />
                    </HyVeePlusWeb>
                </Provider>
            );
        }
    }

    return WithRedux;
};

export default withRedux;
